import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import img from "../assets/back-layer.png"
import linkedIn from "../assets/forma-1-copy_2019-12-25/forma-1-copy.png"
import Expansion from '../components/ourTeam/Expansion/ExpansionPanel'
import Content from "../components/Content"
import Heading from '../components/elements/Heading'
import Layout from "../components/Layout"
import '../pages/profiles.css'

export const query = graphql`
  query($slug: String!) {
    contentfulBannerImages(path: {eq: "/OurTeam"}) {
      image {
        fluid(quality:100) {
          src
        }
      }
      heading
    }
    contentfulProfiles(slug: { eq: $slug }) {
      id
      slug
      name
      designation
      email
      ptclNumber
      cellNumber
      location
      linkedIn
      education{
        childContentfulRichText{
            html
        }
      }
      honorsAndAchievements{
        childContentfulRichText{
            html
        }
      }
      memberships{
        childContentfulRichText{
            html
        }
      }
      image {
        fluid(quality:100) {
          src
        }
      } 
      text1{
        childContentfulRichText{
            html
        }
      }
      text2{
        childContentfulRichText{
            html
        }
      }
      profileBadges {
        id
        fluid(quality: 100) {
            src
        }
      }
    }
  }
`

const heading = `Full Profile`
const path = `profile`
const head1 = `Education`
const head2 = `Honors and Achievements`
const head3 = `Memberships`

const Profiles = props => {
  // console.log("data badges", props.data.contentfulBadges.badge.fluid.src)

  const ImgDiv = styled.div`
  background-image: url(${props.data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
  return (

    <Layout>
      <ImgDiv>
        <h3
          style={{
            fontFamily: "Playfair Display",
            fontSize: 50,
            fontWeight: "bold",
            textAlign: "center",
            margin: "auto",
            paddingTop: 100,
          }}
        >
          {props.data.contentfulBannerImages.heading}
        </h3>
      </ImgDiv>
      <div className="container w-50 mt-5 mb-5" style={{ textAlign: "-webkit-center" }}>

        <Heading className="headingPro" style={{  }}>
          <Content heading={heading} />{" "}
        </Heading>
      </div>

      <div className="container mt-5 text-justify">
        <div className="row profile-layout">
          <div className="col-lg-6 col-sm-12 profile-text" style={{ color: "#000000" }}>
            <p dangerouslySetInnerHTML={{ __html: props.data.contentfulProfiles.text1.childContentfulRichText.html }} />
          </div>
          <div className="col-lg-3 col-md-5 mx-auto col-8 mt-2 profile-img">
            <img
              src={props.data.contentfulProfiles.image[0].fluid.src}
              // className="d-block w-100"
              alt="..."

            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div dangerouslySetInnerHTML={{ __html: props.data.contentfulProfiles.name }}
              style={{
                color: "#94040b",
                borderBottomStyle: "solid",
                borderColor: "#000000",
                fontFamily: "Rawline",
                fontWeight: 600,
                fontSize: 25,
                borderWidth: 1,
              }}
            >

            </div>

            <div style={{ color: "#000000", fontWeight: 500 }}>
              <p style={{ fontSize: "20px" }} className="mt-2">{props.data.contentfulProfiles.designation}</p>
              <p style={{ fontSize: "20px" }} className="mt-3">{props.data.contentfulProfiles.location}</p>
              <p style={{ fontSize: "20px" }} className="mt-3">{props.data.contentfulProfiles.email}</p>
            </div>
            <a href={props.data.contentfulProfiles.linkedIn} target="_blank" rel="noopener noreferrer"><img className="mt-3" src={linkedIn} alt="LinkedIn" /></a>
          </div>
        </div>
        <div className="row" >
          <div className="col-12 profile-text" style={{ color: "#000000" }}>
            <p dangerouslySetInnerHTML={{ __html: props.data.contentfulProfiles.text2.childContentfulRichText.html }} />
          </div>

        </div>
        <div className="row" >
          <div className="col-lg-6 col-md-12" style={{ color: "#94040b", paddingLeft: 17, paddingTop: 30, }}>
            <Expansion education={!props.data.contentfulProfiles.education ? null : props.data.contentfulProfiles.education.childContentfulRichText.html} memberships={!props.data.contentfulProfiles.memberships ? null : props.data.contentfulProfiles.memberships.childContentfulRichText.html} honorsAndAchievements={!props.data.contentfulProfiles.honorsAndAchievements ? null : props.data.contentfulProfiles.honorsAndAchievements.childContentfulRichText.html} path={path} head1={head1} head2={head2} head3={head3} />
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="container">
        <div className="row my-5 center align-items-center">
          {
            !props.data.contentfulProfiles.profileBadges ? null : props.data.contentfulProfiles.profileBadges.map((badge) => {
              return (
                <div className="col-4" key={badge.id}>
                  <div className="center">
                    <a href="https://www.legal500.com/" target="_blank" rel="noopener noreferrer">
                      <img src={badge.fluid.src} alt="" />
                    </a>
                  </div>
                </div>
              )
            })

          }


        </div>
      </div>

    </Layout>

  )
}

export default Profiles
