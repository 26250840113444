import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import './expansion.css'


const useStyles = makeStyles( {
  root: {
    width: "100%",
    borderRadius: 0,
  },
  heading: {
    color : "#94040b",
    fontWeight : 600
  },
})

export default function SimpleExpansionPanel({
  head1,
  head2,
  head3,
  memberships,
  education,
  honorsAndAchievements

}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ExpansionPanel
        style={{
          background: "transparent",
          border: "none",
          borderBottomStyle: "solid",
          borderColor: "#942411",
          borderWidth: 1,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.heading}>
            <h2>{head1}</h2>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p dangerouslySetInnerHTML={{ __html: education }}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        style={{
          background: "transparent",
          border: "none",
          borderBottomStyle: "solid",
          borderColor: "#942411",
          borderWidth: 1,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div className={classes.heading}>
              <h2>{head2}</h2>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <p dangerouslySetInnerHTML={{ __html: honorsAndAchievements }}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        style={{
          background: "transparent",
          border: "none",
          borderBottomStyle: "solid",
          borderColor: "#942411",
          borderWidth: 1,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <div className={classes.heading}>
              <h2>{head3}</h2>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.column}>
            <p dangerouslySetInnerHTML={{ __html: memberships }}/>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

